// @flow

import { Get } from 'api';
import { createAsset } from 'store/asset/actions';

import type { Asset, DamAsset, Dispatch, GetState } from 'types';

// ============================
// ACTION TYPES
// ============================
export const ACTIONS = {
  LIBRARY: {
    REQUEST: 'LIBRARY::REQUEST',
    RECEIVE: 'LIBRARY::RECEIVE',
    ERROR: 'LIBRARY::ERROR'
  }
};

// ============================
// ACTION CREATORS
// ============================
function _receiveLibrary(assets: Asset[]) {
  return {
    type: ACTIONS.LIBRARY.RECEIVE,
    assets
  };
}

function _requestLibrary() {
  return {
    type: ACTIONS.LIBRARY.REQUEST
  };
}

function _errorLibrary(error: { message: string }) {
  return {
    type: ACTIONS.LIBRARY.ERROR,
    error
  };
}

// ============================
// ACTION METHODS
// ============================
export const fetchLibrary = () => (dispatch: Dispatch, getState: GetState) => {
  // if we already fetched plants, no need to do it again
  if (getState().library.assets.length) return;

  dispatch(_requestLibrary());

  Get('virtualplants/library')
    .then((response) => {
      if (response.status !== 200) {
        dispatch(_errorLibrary(response.data));
        return [];
      }

      const assets = [];

      response.data.forEach((asset: DamAsset) => {
        dispatch(createAsset(asset));
        assets.push(asset.id);
      });

      return assets;
    })
    .then((assets) => {
      dispatch(_receiveLibrary(assets));
    });
};
