// @flow

import React, { useEffect, useState } from 'react';

import ImageCard from 'components/SimpleCard/ImageCard/ImageCard';
import LiteratureCard from 'components/SimpleCard/LiteratureCard/LiteratureCard';
import VideoCard from 'components/SimpleCard/VideoCard/VideoCard';

import type { Asset } from 'types';

type Props = {
  assets: Asset[]
};

/**
 * Build and output a list of asset cards, using a specific card
 * component based on the asset type. Intended for easy usage
 * within a SimpleCardList.
 * <SimpleCardList>
 *   <AssetCards assets={assets} />
 * </SimpleCardList>
 *
 * @author Todd Miller <github.com/Toddses>
 */
const AssetCards = (props: Props) => {
  const { assets } = props;
  const [cards, setCards] = useState(null);

  const buildAssetCards = (asset, CardComponent) => {
    const cardList = [];

    asset.files.forEach((file, index) => {
      let languageFormat = '';

      if (file.language) {
        languageFormat = `(${file.language}${
          file.format ? ', ' + file.format : ''
        })`;
      }

      cardList.push(
        <CardComponent
          key={`${asset.id}-${index}`}
          image={asset.card}
          path={file.file}
          title={`${asset.title} ${languageFormat}`}
        />
      );
    });

    return cardList;
  };

  useEffect(() => {
    if (!assets || !assets.length) return;

    const components = [];

    assets.forEach((asset) => {
      if (asset.type === 'product_images')
        components.push(buildAssetCards(asset, ImageCard));
      else if (asset.type === 'videos')
        components.push(buildAssetCards(asset, VideoCard));
      else components.push(buildAssetCards(asset, LiteratureCard));
    });

    setCards(components);
  }, [assets]);

  return <>{cards}</>;
};

export default AssetCards;
