// @flow

import React from 'react';

import './PlantsView.scss';
import Panel from 'components/Panel/Panel';
import PlantCardList from 'components/PlantCardList/PlantCardList';

import type { Element } from 'react';
import type { Plant } from 'types';

type Props = {
  plants: Plant[],
  plantIcon: Element<any>
};

const PlantsView = (props: Props) => {
  const { plants, plantIcon } = props;

  return (
    <div id="plants-view">
      <Panel>
        <PlantCardList
          icon={plantIcon}
          plantList={plants}
          title="Plant Selection"
        />
      </Panel>
    </div>
  );
};

export default PlantsView;
