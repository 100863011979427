// @flow

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

/**
 * Bootstraps everything and attaches the global container
 */

import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import * as serviceWorker from 'serviceWorker';

import 'styles/app.scss';
import AppContainer from 'containers/AppContainer';
import configureStore from './configureStore';

const store = configureStore();

const $root = document.getElementById('root');

if ($root === null) throw new Error('No `root` Element exists in the DOM');

render(
  <Provider store={store}>
    <Router>
      <AppContainer />
    </Router>
  </Provider>,
  $root
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
