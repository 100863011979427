// @flow

// determine if the given item exists in the given array,
// based on the given string id. note the array items
// must be objects with an ID property
export const doesIdExistInArray = <T: { id: string }>(
  arr: T[],
  id: string
): boolean => {
  return arr.findIndex((item) => item.id === id) >= 0;
};

// combines the given arrays and removes duplicate entries
// argument must be an array of objects containing an
// `items` property, which is itself an array of strings
export const uniquelyConcatArrays = <T: { items: string[] }>(
  arrays: T[]
): string[] => {
  const allItems = [];

  arrays.forEach((array) => {
    array.items.forEach((item) => {
      allItems.push(item);
    });
  });

  return [...new Set(allItems)];
};
