//@flow

import React from 'react';

import './ImageView.scss';
import ImageWithPlaceholder from 'components/ImageWithPlaceholder/ImageWithPlaceholder';
import Panel from 'components/Panel/Panel';
import Title from 'components/Title/Title';

type Props = {
  image: string,
  title: string
};

/**
 * Outputs full width responsive image in lieu of product information
 * @author Abigail Kesler <github.com/abbingail>
 * @author Todd Miller <github.com/Toddses>
 */
const ImageView = (props: Props) => {
  const { image, title } = props;

  return (
    <div id="image-view">
      <Panel>
        <Title copy={title} theme="light" />

        <div className="image-wrapper">
          <ImageWithPlaceholder src={image} alt={title} />
        </div>
      </Panel>
    </div>
  );
};

export default ImageView;
