// @flow

import { ACTIONS } from './actions';

import type { Asset } from 'types';

export type AssetState = {
  [id: string]: Asset
};

export type AssetAction = {
  type: string | null,
  asset: Asset
};

const defaultState = {};

// asset reducer
const asset = (state: AssetState = defaultState, action: AssetAction) => {
  switch (action.type) {
    case ACTIONS.ASSET.RECEIVE:
      return {
        ...state,
        [action.asset.id]: action.asset
      };

    default:
      return state;
  }
};

export default asset;
