// @flow

import React from 'react';

type Props = {
  iconTitle: string
};

const FeedbackIcon = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 25.7"
      aria-labelledby={props.iconTitle}
    >
      <title id={props.iconTitle}>Feedback and Bug Report Icon</title>

      <g>
        <path d="M14.5,12.8h-2V8.9c0-1.3,1.1-2.4,2.4-2.4h6.3v2h-6.3c-0.2,0-0.4,0.2-0.4,0.4V12.8z" />
      </g>
      <path d="M29.6,6.5h-4.4v-4c0-1.4-1.2-2.6-2.6-2.6H2.6C1.2,0,0,1.2,0,2.6v11.7c0,1.4,1.2,2.6,2.6,2.6h1.5v4.8c0,0.4,0.3,0.8,0.7,0.9 c0.1,0,0.2,0.1,0.3,0.1c0.3,0,0.6-0.1,0.8-0.4l4.5-5.4l2.2,0v2.3c0,1.3,1.1,2.4,2.4,2.4h8.9l3.2,3.9c0.2,0.2,0.5,0.4,0.8,0.4 c0.1,0,0.2,0,0.3-0.1c0.4-0.1,0.7-0.5,0.7-0.9v-3.2h0.8c1.3,0,2.4-1.1,2.4-2.4V8.9C32,7.6,30.9,6.5,29.6,6.5z M9.9,14.8 c-0.3,0-0.6,0.1-0.8,0.4l-3,3.6v-3c0-0.6-0.4-1-1-1H2.6c-0.3,0-0.6-0.3-0.6-0.6V2.6C2,2.3,2.3,2,2.6,2h20.1c0.3,0,0.6,0.3,0.6,0.6 v11.7c0,0.3-0.3,0.6-0.6,0.6L9.9,14.8z M30,19.1c0,0.2-0.2,0.4-0.4,0.4h-1.8c-0.6,0-1,0.4-1,1v1.5l-1.8-2.1 c-0.2-0.2-0.5-0.4-0.8-0.4h-9.4c-0.2,0-0.4-0.2-0.4-0.4v-2.3l8.1,0c1.4,0,2.6-1.2,2.6-2.6v0V8.5h4.4c0.2,0,0.4,0.2,0.4,0.4V19.1z" />
      <g>
        <rect x="5.4" y="6.9" width="5.1" height="2" />
      </g>
      <g>
        <rect x="16.5" y="10.8" width="4.7" height="2" />
      </g>
    </svg>
  );
};

export default FeedbackIcon;
