// @flow

import React from 'react';
import objectFitImages from 'object-fit-images';

import './FullScreenBackground.scss';

type Props = {
  disabled?: boolean,
  image: string,
  onLoaded?: () => void
};

/**
 * Fullscreen Background will accept an string: image and will set it
 * as a background for a full width and height container . Also has a
 * disabled state where the container will be hidden if disable is set.
 * Can also optionally pass onLoaded function, that will be called
 * when the image finishes downloading.
 *
 * @author Matthew Wilson <github.com/matthewwewilson>
 * @author Todd Miller <github.com/Toddses>
 */
const FullScreenBackground = (props: Props) => {
  const { disabled = false, image, onLoaded = null } = props;

  const handleOnLoaded = () => {
    objectFitImages();
    if (onLoaded) onLoaded();
  };

  return (
    <div className={`full-background ${disabled ? 'is-disabled' : ''}`}>
      <img src={image} alt="VirtualPlant Unit" onLoad={handleOnLoaded} />
    </div>
  );
};

export default FullScreenBackground;
