import axios from 'axios';

// execute a POST request via axios
function request(endpoint: string) {
  const url = process.env.REACT_APP_API_URL || '';

  return axios({
    url: url,
    method: 'POST',
    data: {
      endpoint: endpoint,
      permissions: localStorage.getItem('permissions')
    }
  })
    .then((response) => {
      if (response.status === 200) {
        console.log(response);
        console.log(response.data);
        console.log(response.data.data);
        try {
          response.data.data = JSON.parse(response.data.data);
        } catch (e) {
          console.error(e);
        }

        return response.data;
      } else {
        if (response.status === 500) {
          console.error(response.data.data.message);
          return response.data;
        }
      }
    })
    .catch((error, msg) => {
      console.error(msg);
      console.error(error);
    });
}

// provide a Get function for executing API requests
export const Get = (endpoint: string) => {
  return request(endpoint);
};
