// @flow

import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import './PlantCardList.scss';
import PlantCard from 'components/PlantCard/PlantCard';
import ScrollIndicator from 'components/ScrollIndicator/ScrollIndicator';

import type { Element } from 'react';
import type { Plant } from 'types';

type Props = {
  icon: Element<any>,
  plantList: Plant[],
  title: string
};

/**
 * Outputs an icon, a list title, and a list of PlantCard components. You should pass in
 * your PlantCard components as a Plant array. Requires a string for the title
 * and an component for the icon.
 *
 * @author Matthew Wilson <github.com/matthewwewilson>
 */
const PlantCardList = (props: Props) => {
  const { plantList, title, icon } = props;

  const plants = plantList.map((plant) => (
    <PlantCard
      key={plant.id}
      title={plant.title}
      path={`/plants/${plant.id}`}
      image={plant.card}
    />
  ));

  return (
    <>
      <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true}>
        <div className="plant-list-container">
          <div className="title-bar">
            {icon}
            <h1>{title}</h1>
          </div>
          <div className="plant-list">{plants}</div>
        </div>
      </Scrollbars>

      <ScrollIndicator />
    </>
  );
};

export default PlantCardList;
