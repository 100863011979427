// @flow

import React from 'react';

type Props = {
  iconTitle: string
};

const VirtualPlantFilledIcon = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      aria-labelledby={props.iconTitle}
    >
      <title id={props.iconTitle}>Virtual Plant Icon</title>

      <g>
        <path d="M28.3,2.4c-1,1-2.5,0.9-4.1,0.7c1.2-0.6,2-1.6,2.2-3.1c-3.1,4.6-10.6-5.5-15.6,5c1.8-0.6,4.8-0.5,7.7-0.7 C20.9,6.8,27.7,6.6,28.3,2.4z" />
        <path d="M29.5,25.4h-0.5v-9l-7.1,1.8l-1.1-2.6l-7,2.7L13,8.4H8.4L7.6,23.5l-1-10.7H2L1.2,25.4H0v3.8h30v-3.8H29.5z M19.5,24.2h-3v-4.2h3L19.5,24.2z M26,24.2h-3v-4.2h3L26,24.2z" />
      </g>
    </svg>
  );
};

export default VirtualPlantFilledIcon;
