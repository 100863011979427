// @flow

import React from 'react';

import './LibraryView.scss';
import AssetCards from 'components/AssetCards/AssetCards';
import Panel from 'components/Panel/Panel';
import SimpleCardList from 'components/SimpleCardList/SimpleCardList';
import Title from 'components/Title/Title';

import type { Asset } from 'types';

type Props = {
  assets: Asset[]
};

/**
 * Outputs the Library, a pretty straight forward SimpleCardList,
 * outputting the given list of card elements.
 * @author Todd Miller <github.com/Toddses>
 */
const LibraryView = (props: Props) => {
  return (
    <Panel>
      <div id="library-view">
        <Title copy="VirtualPlant Library" theme="light" />
        <SimpleCardList>
          <AssetCards assets={props.assets} />
        </SimpleCardList>
      </div>
    </Panel>
  );
};

export default LibraryView;
