// @flow

import React from 'react';

import './LiteratureCard.scss';
import CircleDocumentIcon from 'icons/CircleDocumentIcon';
import SimpleCard from '../SimpleCard';

type Props = {
  image: string,
  path: string,
  title: string
};

/**
 * Uses SimpleCard composition to output a card with a "document" button
 * @author Todd Miller <github.com/Toddses>
 */
const LiteratureCard = (props: Props) => {
  const { image, path, title } = props;
  const icon = <CircleDocumentIcon iconTitle="document-icon" />;

  return (
    <SimpleCard
      image={image}
      overlayCopy="View"
      button={icon}
      title={title}
      path={path}
      newTab={true}
    />
  );
};

export default LiteratureCard;
