// @flow

import React from 'react';

type Props = {
  iconTitle: string
};

const HotspotIcon = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 37.9 54.8"
      aria-labelledby={props.iconTitle}
    >
      <title id={props.iconTitle}>Hotspot Icon</title>

      <path
        className="border"
        d="M18.9 0C8.5 0 0 8.5 0 18.9c0 2.8.4 4.9 1.3 6.8l12.1 25.6c1 2.1 3.2 3.5 5.6 3.5 2.4 0 4.6-1.4 5.6-3.5l12-25.6c.9-1.8 1.3-4 1.3-6.8C37.9 8.5 29.4 0 18.9 0z"
      />
      <path
        className="arrow"
        d="M34.8 24.9l-12 25.6c-.7 1.5-2.2 2.3-3.8 2.3s-3.1-.9-3.8-2.3L3.1 24.9C2.2 23 2 21 2 18.9 2 9.6 9.6 2 18.9 2s16.9 7.6 16.9 16.9c.1 2.1-.2 4.1-1 6z"
      />
      <path
        className="circle"
        d="M18.9 10.5c-4.7 0-8.5 3.8-8.5 8.5s3.8 8.5 8.5 8.5 8.5-3.8 8.5-8.5-3.8-8.5-8.5-8.5z"
      />
    </svg>
  );
};

export default HotspotIcon;
