// @flow

import { doesIdExistInArray } from 'helpers';
import type { Dispatch, GetState } from 'types';

// ============================
// ACTION TYPES
// ============================
export const ACTIONS = {
  BUTTBAR: {
    ACTIVATE: 'BUTTBAR::ACTIVATE',
    DEACTIVATE: 'BUTTBAR::DEACTIVATE',
    DISABLE: 'BUTTBAR::DISABLE',
    ENABLE: 'BUTTBAR::ENABLE'
  },
  BUTT: {
    RECEIVE: 'BUTT::RECEIVE',
    ACTIVATE: 'BUTT::ACTIVATE',
    DEACTIVATE: 'BUTT::DEACTIVATE',
    HOVER: 'BUTT::HOVER',
    DEHOVER: 'BUTT::DEHOVER',
    CLEAR: 'BUTT::CLEAR'
  }
};

// ============================
// ACTION CREATORS
// ============================
export const activateButtBar = () => {
  return {
    type: ACTIONS.BUTTBAR.ACTIVATE
  };
};

export const deactivateButtBar = () => {
  return {
    type: ACTIONS.BUTTBAR.DEACTIVATE
  };
};

export const enableButtBar = () => {
  return {
    type: ACTIONS.BUTTBAR.ENABLE
  };
};

export const disableButtBar = () => {
  return {
    type: ACTIONS.BUTTBAR.DISABLE
  };
};

export const activateButt = (id: string) => {
  return {
    type: ACTIONS.BUTT.ACTIVATE,
    id
  };
};

export const deactivateButt = () => {
  return {
    type: ACTIONS.BUTT.DEACTIVATE
  };
};

export const hoverButt = (id: string) => {
  return {
    type: ACTIONS.BUTT.HOVER,
    id
  };
};

export const dehoverButt = () => {
  return {
    type: ACTIONS.BUTT.DEHOVER
  };
};

export const clearButts = () => {
  return {
    type: ACTIONS.BUTT.CLEAR
  };
};

const _receiveButt = (butt) => {
  return {
    type: ACTIONS.BUTT.RECEIVE,
    butt
  };
};

// ============================
// ACTION METHODS
// ============================
export const createButt = (id: string, title: string) => (
  dispatch: Dispatch,
  getState: GetState
) => {
  if (doesIdExistInArray(getState().butt.butts, id)) {
    if (process.env.REACT_APP_DEBUG_LOGGING)
      console.debug(`Cannot create butt "${id}", it already exists!`);
  }

  dispatch(
    _receiveButt({
      id: id,
      title: title
    })
  );
};
