// @flow

import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import './LandingView.scss';
import LandingCTA from 'components/LandingView/LandingCTA/LandingCTA';
import Panel from 'components/Panel/Panel';

import LogoIcon from 'icons/LogoIcon';

type Props = {
  intro: string,
  landingOptions: any[],
  title: string,
  ctaClick: (string, boolean | void) => void
};

const LandingView = (props: Props) => {
  const { intro, landingOptions, title, ctaClick } = props;

  return (
    <Panel>
      <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true}>
        <div className="landing-page">
          <div className="intro-group">
            <a
              className="logo"
              href="https://flowserve.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LogoIcon iconTitle="logo" />
            </a>
            <div className="intro-text">
              <h3 className="title">{title}</h3>
              <h5 className="body">{intro}</h5>
            </div>
          </div>

          <div className="landing-sides">
            {landingOptions.map((option) => {
              return (
                <LandingCTA
                  key={option.id}
                  id={option.id}
                  body={option.body}
                  buttonText={option.button}
                  icon={option.icon}
                  title={option.title}
                  url={option.url}
                  ctaClick={ctaClick}
                />
              );
            })}
          </div>
        </div>
      </Scrollbars>
    </Panel>
  );
};

export default LandingView;
