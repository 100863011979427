// @flow

import React, { useEffect, useState } from 'react';

import './ImageWithPlaceholder.scss';
import VirtualPlantIcon from 'icons/VirtualPlantFilledIcon';

type Props = {
  alt: string,
  height?: string,
  src: string
};

/**
 * Displays a stylized placeholder "image" while the given
 * src image is loading, then gracefully fades in the src image.
 * Optionally pass a string for a CSS height value, and that will be
 * used as the height for placeholder.
 *
 * @author Todd Miller <github.com/Toddses>
 */
const ImageWithPlaceholder = (props: Props) => {
  const { alt, height = '', src } = props;
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(false);
  }, [src]);

  return (
    <div className={`image-placeholder ${loaded ? 'is-loaded' : ''}`}>
      <div className="underlay" style={{ height: height }}>
        <VirtualPlantIcon iconTitle="virtual-plant-image-placeholder-icon" />
      </div>
      <img
        src={src}
        alt={alt}
        onLoad={() => {
          setLoaded(true);
        }}
      />
    </div>
  );
};

export default ImageWithPlaceholder;
