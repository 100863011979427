// @flow

import React from 'react';

type Props = {
  iconTitle: string
};

const CloseIcon = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 27.4 27.4"
      aria-labelledby={props.iconTitle}
    >
      <title id={props.iconTitle}>Feedback and Bug Report Icon</title>

      <polygon points="27.4,1.4 26,0 13.7,12.3 1.4,0 0,1.4 12.3,13.7 0,26 1.4,27.4 13.7,15.1 26,27.4 27.4,26 15.1,13.7 " />
    </svg>
  );
};

export default CloseIcon;
