//@flow

import React from 'react';

import './ProductView.scss';
import AssetCards from 'components/AssetCards/AssetCards';
import CloseIcon from 'icons/CloseIcon';
import LiteratureSelector from 'components/ProductView/LiteratureSelector/LiteratureSelector';
import Panel from 'components/Panel/Panel';
import ProductImage from 'components/ProductView/ProductImage/ProductImage';
import SimpleCardList from 'components/SimpleCardList/SimpleCardList';
import VirtualPlantIcon from 'icons/VirtualPlantFilledIcon';

import type { LitType, Product } from 'types';

type Props = {
  activeLitType: string,
  hasError: ?boolean,
  litType: LitType[],
  product: Product,
  pagerInfo: any[],
  deactivateAsset: () => void,
  pagerOnClick: (string) => void,
  selectorOnClick: (string) => void
};

/**
 * Wraps the ProductImage, SimpleCardList, and LiteratureSelector
 * inside a Panel.
 *
 * SimpleCardList transitions frop top down when activated, overlays
 * the ProductImage.
 *
 * @author Abigail Kesler <https://github.com/abbingail>
 */
const ProductView = (props: Props) => {
  const {
    activeLitType,
    hasError,
    litType,
    product,
    pagerInfo,
    deactivateAsset,
    pagerOnClick,
    selectorOnClick
  } = props;

  function compileCardList(litType) {
    const assetCardList = [];
    for (let i = 0; i < litType.length; i++) {
      const eachCardList = (
        <div
          key={`${litType[i].id}-card-list`}
          className={`card-list ${
            activeLitType === litType[i].id ? 'is-active' : ''
          } ${activeLitType === '' ? 'no-delay' : ''}`}
        >
          <SimpleCardList onClose={deactivateAsset}>
            <AssetCards assets={litType[i].assets} />
          </SimpleCardList>
        </div>
      );
      assetCardList.push(eachCardList);
    }
    return assetCardList;
  }

  return hasError ? (
    <Panel>
      <div id="product-error">
        <VirtualPlantIcon iconTitle="product-error-virtual-plant-icon" />
        <div className="questionable">
          <CloseIcon iconTitle="product-error-close-icon" />
        </div>
        <div className="copy">Product Error</div>
      </div>
    </Panel>
  ) : (
    <Panel>
      <div id="product-view">
        <div className="left-side-wrap">
          <ProductImage id={product.id} image={product.image} />
          {compileCardList(litType)}
        </div>
        <LiteratureSelector
          id={product.id}
          title={product.title}
          description={product.description}
          activeLitType={activeLitType}
          litType={litType}
          pagerInfo={pagerInfo}
          pagerOnClick={pagerOnClick}
          selectorOnClick={selectorOnClick}
        />
      </div>
    </Panel>
  );
};

export default ProductView;
