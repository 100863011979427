// @flow

import React from 'react';
import { useSelector } from 'react-redux';

import Loading from 'components/Loading/Loading';
import SimpleCardList from 'components/SimpleCardList/SimpleCardList';
import ProductCard from 'components/SimpleCard/ProductCard/ProductCard';
import { getProducts } from 'store/product/selectors';

import type { Product } from 'types';

type Props = {
  products: string[],
  showPrimary?: boolean
};

/**
 * Use component composition to output a list of ProductCards
 * - `products` is a list of product IDs that should be output
 * - `showPrimary` optionally determine if the primary notation
 *   should be output on the first card in the list.
 *   defautls to false (eg. no notation)
 *
 * @author Todd Miller <github.com/Toddses>
 */
const ProductCardList = (props: Props) => {
  const { products, showPrimary = false } = props;
  const productData = useSelector(getProducts(products));

  // determine if all given products have been loaded into the store,
  // we use this to determine if we should display some type of
  // loading screen or if its safe to display the product list
  const isAllProductsLoaded = () => {
    for (let i = 0; i < productData.length; i++) {
      if (!productData[i]) return false;
    }

    return true;
  };

  return (
    <>
      <Loading active={!isAllProductsLoaded()} relative={true} />
      {isAllProductsLoaded() && (
        <SimpleCardList>
          {productData.map((product: Product, index: number) => {
            return (
              <ProductCard
                key={product.id}
                image={product.card}
                path={`/product/${product.id}`}
                title={`${product.title} ${
                  product.brand ? `(${product.brand})` : ''
                }`}
                description={product.shortDescription}
                certifications={product.certification}
                primary={showPrimary && index === 0}
              />
            );
          })}
        </SimpleCardList>
      )}
    </>
  );
};

export default ProductCardList;
