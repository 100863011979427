// @flow

import { ACTIONS } from './actions';

export type LibraryState = {
  isFetching: boolean,
  hasError: boolean,
  error: {
    message: string,
    status: number
  },
  assets: string[]
};

export type LibraryAction = {
  type: string | null,
  error: {
    message: string,
    status: number
  },
  assets: string[]
};

const defaultState = {
  isFetching: false,
  hasError: false,
  error: {},
  assets: []
};

// library reducer
const library = (state: LibraryState = defaultState, action: LibraryAction) => {
  switch (action.type) {
    case ACTIONS.LIBRARY.REQUEST:
      return {
        ...state,
        isFetching: true
      };

    case ACTIONS.LIBRARY.ERROR:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: action.error
      };

    case ACTIONS.LIBRARY.RECEIVE:
      return {
        ...state,
        isFetching: false,
        hasError: false,
        assets: action.assets
      };

    default:
      return state;
  }
};

export default library;
