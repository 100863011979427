// @flow

import React from 'react';

import './MenuItem.scss';
import ChevronRightIcon from 'icons/ChevronRightIcon';

type Props = {
  active: boolean,
  copy: string,
  id: string,
  path?: string,
  onClick: (id: string, path?: string) => void
};

/**
 * Outputs a generic MenuItem. Can be used with Component Composition to
 * build more uniquely styled MenuItems
 * - active determines if the active state styles should be applied
 * - copy is the item's text
 * - id is a unique identifer used in the click handler to identify
 *   which menu item was clicked
 * - executes the given function when clicked
 *
 * @author Todd Miller <github.com/Toddses>
 */
const MenuItem = (props: Props) => {
  const { active, copy, id, path = '', onClick } = props;

  const handleClick = () => {
    if (path) onClick(id, path);
    else onClick(id);
  };

  return (
    <div
      className={`menu-item w-bold ${active ? 'is-active' : ''}`}
      onClick={handleClick}
    >
      <div className="copy">{copy}</div>
      <ChevronRightIcon iconTitle="menu-item-right-chevron" />
    </div>
  );
};

export default MenuItem;
