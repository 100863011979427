// @flow

import React from 'react';

import './SideBar.scss';
import Drawer from '../Drawer/Drawer';
import Hamburger from '../Hamburger/Hamburger';
import Menu from '../Menu/Menu';
import TierOneItem from '../Menu/TierOneItem/TierOneItem';
import TierTwoItem from '../Menu/TierTwoItem/TierTwoItem';
import Widget from '../Widget/Widget';

import type { DrawerItem, SidebarWidget } from 'features/SideBar/types';

type Props = {
  activeDrawerItem: string,
  activeMenuItem: string,
  drawerActive: boolean,
  drawerItems: DrawerItem[],
  hamburgerClick: () => void,
  tierOneMouseEnter: (id: string) => void,
  tierOneClick: (id: string, path?: string) => void,
  tierTwoClick: (id: string) => void,
  widgets: SidebarWidget[]
};

/**
 * Outputs the SideBar wrappers and components.
 * @author Todd Miller <github.com/Toddses>
 */
const SideBar = (props: Props) => {
  const {
    activeDrawerItem,
    activeMenuItem,
    drawerActive,
    drawerItems,
    hamburgerClick,
    tierOneMouseEnter,
    tierOneClick,
    tierTwoClick,
    widgets
  } = props;

  // builds the Component output for Tier One MenuItems
  const buildTierOneMenu = (items, viewAll = '') => {
    const comps = items.map((item) => (
      <TierOneItem
        key={item.id}
        id={item.id}
        active={activeMenuItem === item.id}
        copy={item.title}
        onClick={tierOneClick}
        onMouseEnter={tierOneMouseEnter}
      />
    ));

    if (viewAll) {
      comps.push(
        <TierOneItem
          key="tier-one-view-all"
          id="tier-one-view-all"
          active={activeMenuItem === 'tier-one-view-all'}
          copy="View All"
          path={viewAll}
          onClick={tierOneClick}
          onMouseEnter={tierOneMouseEnter}
        />
      );
    }

    return comps;
  };

  // builds the Component output for Tier Two MenuItems
  const buildTierTwoMenu = (items) => {
    return items.map((item) => (
      <TierTwoItem
        key={item.id}
        id={item.id}
        active={false}
        copy={item.title}
        onClick={tierTwoClick}
      />
    ));
  };

  // for easy iteration, normalize the Menu structure for Tier One Menus
  const tierOneMenus = [];
  drawerItems.forEach((item) => {
    tierOneMenus.push({
      id: item.id,
      output: buildTierOneMenu(item.tierOne, item.tierOneViewAll)
    });
  });

  // for easy iteration, normalize the Menu structure for Tier Two Menus
  const tierTwoMenus = [];
  drawerItems.forEach((item) => {
    Object.keys(item.tierTwo).forEach((key) => {
      tierTwoMenus.push({
        id: key,
        output: buildTierTwoMenu(item.tierTwo[key])
      });
    });
  });

  return (
    <div id="side-bar">
      <div
        className={`overlay ${drawerActive ? 'is-active' : ''}`}
        onClick={hamburgerClick}
      />

      <div className="widget-container">
        <Hamburger onClick={hamburgerClick} />

        {widgets.map((widget) => {
          return (
            <Widget
              key={widget.id}
              id={widget.id}
              copy={widget.copy}
              icon={widget.icon}
              handler={widget.handler}
            />
          );
        })}
      </div>

      <div className={`drawer-container ${drawerActive ? 'is-active' : ''}`}>
        <Drawer
          active={drawerActive}
          activeDrawer={activeDrawerItem}
          items={drawerItems}
          onClose={hamburgerClick}
        />

        {tierOneMenus.map((menu) => {
          return (
            <Menu key={menu.id} tier={1} active={activeDrawerItem === menu.id}>
              {menu.output}
            </Menu>
          );
        })}

        {tierTwoMenus.map((menu) => {
          return (
            <Menu key={menu.id} tier={2} active={activeMenuItem === menu.id}>
              {menu.output}
            </Menu>
          );
        })}
      </div>
    </div>
  );
};

export default SideBar;
