// @flow

import { createSelector } from 'reselect';

/**
 * Returns the active filter on the given filter type id
 */
export const getActiveFilterId = (id: string) => {
  return createSelector(
    (state) => state.filter,
    (filter) => (filter[id] ? filter[id].active : '')
  );
};

export const getActiveFilterItems = (id: string) => {
  return createSelector(
    (state) => state.filter,
    (filter) => {
      if (!filter[id]) return [];

      return filter[id].currentItems.length
        ? filter[id].currentItems
        : filter[id].allItems;
    }
  );
};

/**
 * Returns the string array of the compiled list of all
 * items on the given filter type id
 */
export const getAllItems = (id: string) => {
  return createSelector(
    (state) => state.filter,
    (filter) => (filter[id] ? filter[id].allItems : [])
  );
};
