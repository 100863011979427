// @flow

import { ACTIONS } from './actions';

export type FilterState = {
  [id: string]: {
    active: string,
    allItems: string[],
    currentItems: string[]
  }
};

export type FilterAction = {
  type: string | null,
  allItems: string[],
  id: string,
  items: string[],
  filterId: string
};

const defaultState = {};

// filter reducer
const filter = (state: FilterState = defaultState, action: FilterAction) => {
  switch (action.type) {
    case ACTIONS.FILTER.CLEAR:
      return {};

    case ACTIONS.FILTER.SET:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          active: action.filterId,
          currentItems: action.items
        }
      };

    case ACTIONS.FILTER.SETALL:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          allItems: action.allItems,
          currentItems: state[action.id]
            ? [].concat(state[action.id].currentItems)
            : []
        }
      };

    default:
      return state;
  }
};

export default filter;
