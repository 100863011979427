// @flow

import React from 'react';

import './TabBar.scss';
import Tab from '../Tab/Tab';

import type { Tab as TabType } from 'features/TabbedPanel/types';

type Props = {
  activeTab: string,
  tabs: TabType[],
  tabOnClick: (string) => void
};

/*
 * Builds and styles all given tabs horizontally
 *
 * @author Abigail Kesler <https://github.com/abbingail>
 */

const TabBar = (props: Props) => {
  const { activeTab, tabs, tabOnClick } = props;

  return (
    <div className="tab-list">
      {tabs.map((tab) => {
        return (
          <Tab
            key={tab.id}
            id={tab.id}
            activeTab={activeTab === tab.id}
            title={tab.title}
            tabOnClick={tabOnClick}
          />
        );
      })}
    </div>
  );
};

export default TabBar;
