// @flow

import { ACTIONS } from 'store/unit/actions';

import type { Unit } from 'types';

export type UnitState = {
  [plantId: string]: {
    isFetching: boolean,
    hasError: boolean,
    units: Unit[]
  }
};

export type UnitAction = {
  type: string | null,
  plantId: string,
  units: Unit[]
};

const defaultState = {};

// unit reducer
const unit = (state: UnitState = defaultState, action: UnitAction) => {
  switch (action.type) {
    case ACTIONS.UNIT.RECEIVE:
      return {
        ...state,
        [action.plantId]: {
          ...state[action.plantId],
          isFetching: false,
          hasError: false,
          units: action.units
        }
      };

    case ACTIONS.UNIT.ERROR:
      return {
        ...state,
        [action.plantId]: {
          ...state[action.plantId],
          isFetching: false,
          hasError: true
        }
      };

    case ACTIONS.UNIT.REQUEST:
      return {
        ...state,
        [action.plantId]: {
          ...state[action.plantId],
          isFetching: true,
          hasError: false,
          units: []
        }
      };

    default:
      return state;
  }
};

export default unit;
