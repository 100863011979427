// @flow

import React from 'react';
import CustomScrollbar from 'react-custom-scrollbars';

import './Modal.scss';
import CloseIcon from 'icons/CloseIcon';

type Props = {
  active: boolean,
  title: string,
  body: string,
  onClose: () => void
};

/**
 * Modal is a full width and height container. It will fill the entire
 * container with a overlay and display a pop up in the center of its container.
 *
 * Requires onClose event handler to be passed in. Along with Title and Body
 * for the Modal content.
 *
 * Modal will call onClose function if  close button is clicked or
 * if somewhere off the modal is clicked.
 *
 * @author Matthew Wilson <https://github.com/matthewwewilson>
 */

const Modal = (props: Props) => {
  const { onClose, title, body, active } = props;

  return (
    <div className={`modal-container ${active ? 'is-active' : ''}`}>
      <div className="overlay" onClick={onClose} />

      <div className="modal">
        <div className="modal-close-button" onClick={onClose}>
          <CloseIcon iconTitle="close-icon" />
        </div>
        <CustomScrollbar>
          <div className="inner-content">
            <h1>{title}</h1>
            <div
              className="modal-body"
              dangerouslySetInnerHTML={{ __html: body }}
            ></div>
          </div>
        </CustomScrollbar>
      </div>
    </div>
  );
};

export default Modal;
