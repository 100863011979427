// @flow

import { doesIdExistInArray } from 'helpers';

import type { Element } from 'react';
import type { Dispatch, GetState } from 'types';

// ============================
// ACTION TYPES
// ============================

export const ACTIONS = {
  SIDEBAR: {
    SET_THEME: 'SIDEBAR::SET_THEME',
    SET_TITLE: 'SIDEBAR::SET_TITLE'
  },
  WIDGET: {
    CREATE: 'SIDEBAR::WIDGET::CREATE',
    ENABLE_ARRAY: 'SIDEBAR::WIDGET::ENABLE_ARRAY',
    ENABLE: 'SIDEBAR::WIDGET::ENABLE',
    REMOVE: 'SIDEBAR::WIDGET::REMOVE'
  }
};

// ============================
// ACTION CREATORS
// ============================
export const createWidget = (
  id: string,
  copy: string,
  icon: Element<any>,
  handler: (string | void) => void
) => (dispatch: Dispatch, getState: GetState) => {
  if (doesIdExistInArray(getState().sidebar.widgets, id)) {
    if (process.env.REACT_APP_DEBUG_LOGGING)
      console.debug(`Could not create widget '${id}', it already exists!`);
    return false;
  }

  return dispatch({
    type: ACTIONS.WIDGET.CREATE,
    id,
    copy,
    icon,
    handler
  });
};

export const enableWidgets = (ids: string[]) => (
  dispatch: Dispatch,
  getState: GetState
) => {
  const validIds = ids.filter((id) => {
    if (!doesIdExistInArray(getState().sidebar.widgets, id)) {
      if (process.env.REACT_APP_DEBUG_LOGGING)
        console.debug(
          `Could not enable widget '${id}', widget does not exist!`
        );
      return false;
    }

    return true;
  });

  return dispatch({
    type: ACTIONS.WIDGET.ENABLE_ARRAY,
    ids: validIds
  });
};

export const removeWidgets = (ids: string[]) => {
  return {
    type: ACTIONS.WIDGET.REMOVE,
    ids
  };
};

export const setTheme = (theme: 'light' | 'dark' | 'none') => {
  return {
    type: ACTIONS.SIDEBAR.SET_THEME,
    theme
  };
};
