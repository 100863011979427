// @flow

import React from 'react';

type Props = {
  iconTitle: string
};

const TechnicalLitIcon = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 30.2"
      aria-labelledby={props.iconTitle}
    >
      <title id={props.iconTitle}>Technical Literature Icon</title>

      <g>
        <polygon points="11.5,21.2 0,21.2 0,8 11.5,8 11.5,9.5 1.5,9.5 1.5,19.7 11.5,19.7  " />
      </g>
      <g>
        <polygon points="11.3,26.1 5.7,26.1 5.7,22.3 7.2,22.3 7.2,24.6 11.3,24.6  " />
      </g>
      <g>
        <polygon points="7.2,6.9 5.7,6.9 5.7,0 25.3,0 25.3,2.9 23.8,2.9 23.8,1.5 7.2,1.5  " />
      </g>
      <g>
        <path d="M32,30.2H12.4V4.1H32V30.2z M13.9,28.7h16.6V5.6H13.9V28.7z" />
      </g>
      <g>
        <rect x="8.9" y="14" width="2.6" height="1" />
      </g>
      <g>
        <rect x="8.9" y="11.5" width="2.6" height="1" />
      </g>
      <g>
        <rect x="3.3" y="11" width="3" height="7" />
      </g>
      <g>
        <rect x="8.2" y="5" width="3.2" height="1" />
      </g>
      <g>
        <rect x="15.5" y="12.7" width="13.3" height="1" />
      </g>
      <g>
        <rect x="17.4" y="7.4" width="9.6" height="3" />
      </g>
      <g>
        <rect x="15.5" y="15.3" width="13.3" height="1" />
      </g>
      <g>
        <rect x="15.5" y="17.7" width="13.3" height="1" />
      </g>
      <g>
        <rect x="15.5" y="20.2" width="13.3" height="1" />
      </g>
    </svg>
  );
};

export default TechnicalLitIcon;
