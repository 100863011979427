// @flow

import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { Route, RouterHistory, Switch, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'debounce';

import FeedbackIcon from 'icons/FeedbackIcon';
import LandingContainer from 'containers/LandingContainer';
import LibraryContainer from 'containers/LibraryContainer';
import Loading from 'components/Loading/Loading';
import Modal from 'components/Modal/Modal';
import ProductContainer from 'containers/ProductContainer';
import VirtualPlantContainer from 'containers/VirtualPlantContainer';
import { getCurrent } from 'store/current/selectors';
import { createWidget } from 'store/sidebar/actions';
import { checkPlant } from 'store/plant/actions';

import type { Location } from 'react-router-dom';

type Props = {
  history: RouterHistory,
  location: Location
};

type State = {
  showModal: boolean
};

const AppContainer = (props: Props, state: State) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const minimumWidth = 1024;
  const loadingActive = useSelector(getCurrent('LoadingActive'));

  // create the feedback widget
  // intentionally created outside of a useEffect block
  // because of the order of execution.
  // creating it here ensures it exists when other containers
  // set up their widgets

  const feedbackIcon = <FeedbackIcon iconTitle="feedback-icon" />;

  const navigateToForm = () => {
    window.open(
      process.env.REACT_APP_FEEDBACK_URL || '',
      '_blank',
      'noopener noreferrer'
    );
  };

  const updateSize = debounce(() => {
    setShowModal(window.innerWidth < minimumWidth);
  }, 500);

  const closeModal = () => {
    setShowModal(false);
    window.sessionStorage.setItem('warning_modal', true);
  };

  useEffect(() => {
    if (!window.sessionStorage.getItem('warning_modal')) {
      updateSize();
      window.addEventListener('resize', updateSize);
    }

    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, [showModal, updateSize]);

  dispatch(
    createWidget(
      'feedback',
      'Feedback/Bug Report',
      feedbackIcon,
      navigateToForm
    )
  );

  useEffect(() => {
    dispatch(checkPlant());
  });

  const queryString = props.location.search.replace('?', '');

  if (queryString !== '') {
    localStorage.setItem('permissions', queryString);
  }

  // set up google analytics
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_KEY, {
      gaOptions: { anonymizeIp: true }
    });

    ReactGA.pageview(window.location.pathname + window.location.search);

    if (document.referrer && document.referrer !== '') {
      ReactGA.ga('send', 'event', {
        eventCategory: 'Referrer',
        eventAction: 'enter',
        eventLabel: 'Referrer URL',
        eventValue: document.referrer
      });
    }

    const unlisten = props.history.listen((location, action) => {
      const path = location.pathname + location.search;

      ReactGA.pageview(path);

      if (window.piTracker) window.piTracker(window.location.origin + path);
    });

    window.onbeforeunload = () => {
      ReactGA.ga('send', 'event', {
        eventCategory: 'pageview',
        eventAction: 'exit',
        eventLabel: 'Last Page',
        eventValue: window.location.pathname + window.location.search
      });
    };

    return () => {
      unlisten();
    };
  }, [props.history]);

  return (
    <>
      <Loading
        active={typeof loadingActive !== 'undefined' ? loadingActive : true}
      />
      <Modal
        active={showModal}
        title={'Not Compatible'}
        body={
          'Virtual Plant is not optimized for smaller screens or mobile devices. Things may not appear as intended if you continue on this device.'
        }
        onClose={closeModal}
      />
      <Switch>
        <Route exact path="/" component={LandingContainer} />
        <Route path="/plants" component={VirtualPlantContainer} />
        <Route path="/product/:id" component={ProductContainer} />
        <Route path="/library" component={LibraryContainer} />
      </Switch>
    </>
  );
};

export default withRouter(AppContainer);
