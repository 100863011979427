// @flow

import React from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import ButtBar from 'features/ButtBar';
import PlantContainer from 'containers/PlantContainer';
import PlantsContainer from 'containers/PlantsContainer';
import SideBar from 'features/SideBar';
import Transitions from 'features/Transitions';
import UnitContainer from 'containers/UnitContainer';
import { fetchPlants } from 'store/plant/actions';

type Props = {};

const VirtualPlantContainer = (props: Props) => {
  const dispatch = useDispatch();
  // intentional dispatch outside of use effect here, to be
  // sure it runs before any child container's useEffect blocks
  dispatch(fetchPlants());

  return (
    <>
      <SideBar />
      <ButtBar />
      <Transitions />

      <Switch>
        <Route exact path="/plants" component={PlantsContainer} />
        <Route exact path="/plants/:plant" component={PlantContainer} />
        <Route path="/plants/:plant/:unit" component={UnitContainer} />
      </Switch>
    </>
  );
};

export default VirtualPlantContainer;
