// @flow

import React from 'react';
import { Link } from 'react-router-dom';

import './DrawerHeader.scss';
import CloseIcon from 'icons/CloseIcon';
import LogoIcon from 'icons/LogoIcon';

type Props = {
  onClose: () => void
};

/**
 * Outputs the Header section - basically just the logo and a close button.
 * Executes the passed function when the close button is clicked.
 *
 * @author Todd Miller <github.com/Toddses>
 */
const DrawerHeader = (props: Props) => {
  return (
    <div id="drawer-header">
      <div className="close-icon" onClick={props.onClose}>
        <CloseIcon iconTitle="close-drawer-icon" />
      </div>

      <div className="logo-icon">
        <Link to="/">
          <LogoIcon iconTitle="flowserve-logo" />
        </Link>
      </div>
    </div>
  );
};

export default DrawerHeader;
