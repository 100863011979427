// @flow

import { createSelector } from 'reselect';

/**
 * [description]
 * @param  {[type]} key: string        [description]
 * @return {[type]}      [description]
 */
export const getCurrent = (key: string) => {
  return createSelector(
    (state) => state.current,
    (current) => current[key]
  );
};
