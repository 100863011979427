// @flow

import { createSelector } from 'reselect';

/**
 * Returns the isFetching value for Loading Screen stuff
 * @returns boolean
 */
export const getIsFetching = createSelector(
  (state) => state.plant,
  (plant) => plant.isFetching
);

/**
 * Return the full plant structure
 * @returns PlantStructure[]
 */
export const getPlantStructure = createSelector(
  (state) => state.plant,
  (plant) => plant.plants
);

/**
 * Return the modal status
 * @returns boolean
 */
export const getShowModal = createSelector(
  (state) => state.plant,
  (plant) => plant.showModal
);

/**
 * Return the modal status
 * @returns boolean
 */
export const getPlantsEnabled = createSelector(
  (state) => state.plant,
  (plant) => plant.enabled
);

/*
 * Return single plant
 */

export const getPlant = (plantId: string) => {
  return createSelector(
    (state) => state.plant,
    (plant) => {
      const item = plant.plants.find((p) => p.id === plantId);
      if (!item) {
        if (process.env.REACT_APP_DEBUG_LOGGING)
          console.debug(
            `Cannot get plant for "${plantId}", id does not exist!`
          );
        return {};
      }
      return item;
    }
  );
};
