// @flow

// 3rd party
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
// components
import Article from 'components/Article/Article';
import BackIcon from 'icons/ArrowLeftIcon';
import DecokingToolIcon from 'icons/DecokingToolIcon';
import FilteredList from 'features/FilteredList';
import InfoIcon from 'icons/InfoIcon';
import Modal from 'components/Modal/Modal';
import ProductCardList from 'components/ProductCardList/ProductCardList';
import UnitView from 'components/UnitView/UnitView';
// redux stores
import {
  activateButtBar,
  clearButts,
  createButt,
  deactivateButt,
  disableButtBar,
  enableButtBar
} from 'store/butt/actions';
import { getActiveButt } from 'store/butt/selectors';
import { setCurrent } from 'store/current/actions';
import { getCurrent } from 'store/current/selectors';
import { setAllItems } from 'store/filter/actions';
import {
  createWidget,
  enableWidgets,
  removeWidgets,
  setTheme
} from 'store/sidebar/actions';
import { fetchUnits } from 'store/unit/actions';
import {
  getIsFetching as getIsFetchingUnits,
  getUnit
} from 'store/unit/selectors';
import { activateTransition, createTransition } from 'store/transition/actions';
import { getIsFetching as getIsFetchingTransits } from 'store/transition/selectors';
// types
import type { Match } from 'react-router-dom';

type Props = {
  match: Match
};

/**
 * Manages state and data for the routed UnitView component
 * @author Todd Miller <github.com/Toddses>
 */
const UnitContainer = (props: Props) => {
  const dispatch = useDispatch();
  // route params
  const plantId = props.match.params.plant;
  const unitId = props.match.params.unit;
  // selectors
  const unit = useSelector(getUnit(plantId, unitId));
  const isFetchingUnits = useSelector(getIsFetchingUnits(plantId));
  const isFetchingTransits = useSelector(getIsFetchingTransits);
  const activePanel = useSelector(getActiveButt);
  const unitActivePlant = useSelector(getCurrent('unitActivePlant'));
  const unitActiveUnit = useSelector(getCurrent('unitActiveUnit'));
  // local state
  const [modalActive, setModalActive] = useState(false);
  const [tabPanels, setTabPanels] = useState([]);

  /**
   * FETCH
   */
  useEffect(() => {
    dispatch(fetchUnits(plantId));
  }, [dispatch, plantId]);

  /**
   * LOADING SCREEN
   */
  useEffect(() => {
    if (isFetchingUnits) dispatch(setCurrent('LoadingActive', true));
    else dispatch(setCurrent('LoadingActive', false));
  }, [dispatch, isFetchingUnits, isFetchingTransits]);

  /**
   * INITIALIZATION/REACTIVATION
   */
  useEffect(() => {
    if (unitActivePlant === plantId && unitActiveUnit === unitId) return;

    dispatch(setCurrent('unitActivePlant', plantId));
    dispatch(setCurrent('unitActiveUnit', unitId));
    dispatch(deactivateButt());

    setTimeout(() => {
      setModalActive(true);
    }, 1000);
  }, [dispatch, plantId, unitActivePlant, unitId, unitActiveUnit]);

  /**
   * TRANSITIONS
   */
  useEffect(() => {
    if (!plantId || !unit.id) return;

    dispatch(
      createTransition(
        `${plantId}-${unit.id}-reverse`,
        unit.transitions.out,
        `/plants/${plantId}`
      )
    );
  }, [dispatch, unit, plantId]);

  /**
   * SIDEBAR
   */
  useEffect(() => {
    if (!unit.id) return;

    // widget icons
    const backIcon = <BackIcon iconTitle="unit-back-icon" />;
    const decokingIcon = <DecokingToolIcon iconTitle="decoking-tool-icon" />;
    const infoIcon = <InfoIcon iconTitle="unit-info-icon" />;

    // widget handlers
    const decokingHandler = () => {
      const win = window.open(
        'https://www.flowserve.com/en/industries/oil-gas/downstream-processing/decoking-technologies/',
        '_blank',
        'noopener noreferrer'
      );
      win.focus();
    };

    const infoIconHandler = () => {
      setModalActive(true);
    };

    const tabPanelBackIconHandler = () => {
      dispatch(deactivateButt());
    };

    const unitBackIconHandler = () => {
      dispatch(disableButtBar());
      dispatch(activateTransition(`${plantId}-${unit.id}-reverse`));
    };

    // widget creators
    dispatch(
      createWidget(
        'decoking-tool',
        'Learn More About Decoking Technologies',
        decokingIcon,
        decokingHandler
      )
    );
    dispatch(
      createWidget('tab-panel-back', 'Back', backIcon, tabPanelBackIconHandler)
    );
    dispatch(createWidget('unit-info', 'Info', infoIcon, infoIconHandler));
    dispatch(createWidget('unit-back', 'Back', backIcon, unitBackIconHandler));

    // clean up the widgets when we go away
    return () => {
      dispatch(
        removeWidgets([
          'decoking-tool',
          'unit-info',
          'unit-back',
          'tab-panel-back'
        ])
      );
    };
  }, [dispatch, unit, plantId]);

  /**
   * BUTT BAR
   */
  useEffect(() => {
    if (!unit.id) return;

    unit.products.forEach((productType) => {
      dispatch(createButt(productType.id, productType.title));
    });

    dispatch(enableButtBar());
    dispatch(activateButtBar());

    // prevents the butt bar from flooding as the user clicks around
    return () => {
      dispatch(clearButts());
    };
  }, [dispatch, unit]);

  /**
   * TABBED PANELS
   */
  useEffect(() => {
    if (!unit.id) return;

    const panels = [];

    unit.products.forEach((productType) => {
      // set up the product tab, which will appear in all panels
      const tabs = [
        {
          id: 'products',
          title: 'Products',
          panel: productType.applications.length ? (
            <FilteredList
              id={productType.id}
              filters={productType.applications}
            />
          ) : (
            <ProductCardList products={productType.products} />
          )
        }
      ];

      // if there's no application filters, we still need to set all items
      // to support the pager on the product page
      if (!productType.applications.length)
        dispatch(setAllItems(productType.id, productType.products));

      // add in the content-based tabs
      if (unit.content[productType.id]) {
        unit.content[productType.id].forEach((content) => {
          tabs.push({
            id: content.id,
            title: content.title,
            panel: <Article body={content.body} />
          });
        });
      }

      panels.push({
        id: productType.id,
        tabs: tabs
      });
    });

    setTabPanels(panels);
  }, [dispatch, unit]);

  // theme setting up and switching based on panel activity
  useEffect(() => {
    if (!unit.id) return;

    if (activePanel) {
      let widgets = [];

      if (unit.id === 'delayed_coking') {
        widgets = process.env.REACT_APP_SHOW_FEEDBACK
          ? ['empty', 'decoking-tool', 'feedback', 'tab-panel-back']
          : ['empty', 'decoking-tool', 'tab-panel-back'];
      } else {
        widgets = process.env.REACT_APP_SHOW_FEEDBACK
          ? ['empty', 'feedback', 'tab-panel-back']
          : ['empty', 'tab-panel-back'];
      }

      dispatch(enableWidgets(widgets));
      dispatch(setTheme('light'));
      dispatch(setCurrent('unitActivePanel', activePanel));
    } else {
      let widgets = [];

      if (unit.id === 'delayed_coking') {
        widgets = process.env.REACT_APP_SHOW_FEEDBACK
          ? ['unit-info', 'decoking-tool', 'feedback', 'unit-back']
          : ['unit-info', 'decoking-tool', 'unit-back'];
      } else {
        widgets = process.env.REACT_APP_SHOW_FEEDBACK
          ? ['unit-info', 'feedback', 'unit-back']
          : ['unit-info', 'unit-back'];
      }

      dispatch(enableWidgets(widgets));
      dispatch(setTheme('dark'));
      dispatch(setCurrent('unitActivePanel', ''));
    }
  }, [dispatch, unit, activePanel]);

  // additional functions
  const deactivateModal = () => {
    setModalActive(false);
  };

  const deactivateTabbedPanel = () => {
    dispatch(deactivateButt());
  };

  return (
    <>
      <Modal
        active={modalActive}
        title={unit.title}
        body={unit.description}
        onClose={deactivateModal}
      />
      <UnitView
        activePanel={activePanel}
        unit={unit}
        tabPanels={tabPanels}
        deactivateTabbedPanel={deactivateTabbedPanel}
      />
    </>
  );
};

export default withRouter(UnitContainer);
