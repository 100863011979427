// @flow

import React from 'react';

type Props = {
  iconTitle: string
};

const ImagesIcon = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 25.9"
      aria-labelledby={props.iconTitle}
    >
      <title id={props.iconTitle}>Images Icon</title>

      <g>
        <path d="M32,25.9H3V3h29V25.9z M4.5,24.4h26V4.5h-26V24.4z" />
      </g>
      <path d="M11.2,8.4c1.2,0,2.1,1,2.1,2.2c0,1.2-1,2.1-2.2,2.1c-1.2,0-2.1-1-2.1-2.2C9,10,9.2,9.4,9.6,9C10,8.6,10.6,8.4,11.2,8.4z M9.4,19.4v-2.6l2.2-2.2c0.3-0.2,0.6-0.2,0.9,0l2.2,2.2l6.6-6.6c0.3-0.2,0.6-0.2,0.9,0L27,15v4.4L9.4,19.4z" />
      <g>
        <polygon points="1.5,21.5 0,21.5 0,0 27.7,0 27.7,1.5 1.5,1.5  " />
      </g>
    </svg>
  );
};

export default ImagesIcon;
