//@flow

import React from 'react';

import './ProductImage.scss';
import ImageWithPlaceholder from 'components/ImageWithPlaceholder/ImageWithPlaceholder';

type Props = {
  id: string,
  image: string
};

/**
 * Outputs the product image, positioned with a z-index,
 * as the SimpleCard component will slide in and out over it.
 *
 * @author Abigail Kesler <https://github.com/abbingail>
 */

const ProductImage = (props: Props) => {
  const { id, image } = props;

  return (
    <div className="product-image">
      <ImageWithPlaceholder src={image} alt={id} height="400px" />
    </div>
  );
};

export default ProductImage;
