// @flow

// 3rd party
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouterHistory, withRouter } from 'react-router-dom';
// components
import BackIcon from 'icons/ArrowLeftIcon';
import PlantView from 'components/PlantView/PlantView';
import PlantWideInfoIcon from 'icons/PlantWideInfoIcon';
// redux stores
import { getAssets } from 'store/asset/selectors';
import { setCurrent } from 'store/current/actions';
import {
  getIsFetching as getIsFetchingPlant,
  getPlant,
  getPlantsEnabled,
  getShowModal
} from 'store/plant/selectors';
import {
  closeModal as closePModal,
  showModal as showPModal
} from 'store/plant/actions';
import { fetchUnits } from 'store/unit/actions';
import {
  getIsFetching as getIsFetchingUnits,
  getUnits
} from 'store/unit/selectors';
import {
  createWidget,
  enableWidgets,
  removeWidgets,
  setTheme
} from 'store/sidebar/actions';
import {
  activateButtBar,
  clearButts,
  createButt,
  deactivateButt,
  deactivateButtBar,
  disableButtBar,
  enableButtBar,
  hoverButt,
  dehoverButt
} from 'store/butt/actions';
import { getActiveButt, getHoverButt } from 'store/butt/selectors';
import { activateTransition, createTransition } from 'store/transition/actions';
import { getIsFetching as getIsFetchingTransits } from 'store/transition/selectors';
// types
import type { Match } from 'react-router-dom';

type Props = {
  history: RouterHistory,
  match: Match
};

/*
 * Container for PlantView. Fetches and outputs plant information,
 * as well as activating and populating ButtBar with all associated units.
 *
 * Syncs behavior between Hotspot and ButtBarItems (hover and click)
 *
 * @author Abigail Kesler < https://github.com/abbingail>
 */

const PlantContainer = (props: Props) => {
  const { history } = props;
  const dispatch = useDispatch();

  //route param
  const plantId = props.match.params.plant;
  // selectors for plant, units, fetching, and butts
  const plant = useSelector(getPlant(plantId));
  const units = useSelector(getUnits(plantId));
  const plantWideAssets = useSelector(getAssets(plant.assets));
  const isFetchingPlant = useSelector(getIsFetchingPlant);
  const isFetchingTransits = useSelector(getIsFetchingTransits);
  const isFetchingUnits = useSelector(getIsFetchingUnits(plantId));
  const activeButt = useSelector(getActiveButt);
  const hoveredButt = useSelector(getHoverButt);
  const showModal = useSelector(getShowModal);
  const plantsEnabled = useSelector(getPlantsEnabled);
  // local store
  const [activeInfo, setActiveInfo] = useState(false);

  // functionality
  useEffect(() => {
    dispatch(fetchUnits(plantId));
  }, [dispatch, plantId]);

  useEffect(() => {
    if (isFetchingPlant || isFetchingUnits || isFetchingTransits)
      dispatch(setCurrent('LoadingActive', true));
    else dispatch(setCurrent('LoadingActive', false));
  }, [dispatch, isFetchingPlant, isFetchingUnits, isFetchingTransits]);

  /**
   * MODAL
   */
  const closePlantModal = () => dispatch(closePModal());

  const showPlantModal = () => dispatch(showPModal());

  /**
   * TRANSITIONS
   */
  useEffect(() => {
    if (!plant.id || isFetchingUnits) return;

    units.forEach((unit) => {
      dispatch(
        createTransition(
          `${plant.id}-${unit.id}`,
          unit.transitions.in,
          `/plants/${plant.id}/${unit.id}`
        )
      );
    });
  }, [dispatch, plant, units, isFetchingUnits]);

  /**
   * SIDEBAR
   */
  // hoisted for infoWidget access
  const closeInfo = () => {
    setActiveInfo(false);
  };

  useEffect(() => {
    // widget icons
    const backIcon = <BackIcon iconTitle="plant-back-icon" />;
    const plantInfoIcon = <PlantWideInfoIcon iconTitle="plant-info-icon" />;

    const plantBack = () => {
      history.push('/plants');
      dispatch(deactivateButtBar());
    };

    const plantInfoBack = () => {
      setActiveInfo(false);
    };

    const plantInfoForward = () => {
      setActiveInfo(true);
    };

    // widget creators
    dispatch(createWidget('plant-back', 'Back', backIcon, plantBack));
    dispatch(createWidget('plant-info-back', 'Back', backIcon, plantInfoBack));
    dispatch(
      createWidget(
        'plant-info',
        'Plant-wide information',
        plantInfoIcon,
        plantInfoForward
      )
    );

    // clean up the widgets when we go away
    return () => {
      dispatch(removeWidgets(['plant-back', 'plant-info-back', 'plant-info']));
    };
  }, [dispatch, history]);

  useEffect(() => {
    // determines widget and theme based on if plant-wide widget is active
    if (activeInfo) {
      const widgets = process.env.REACT_APP_SHOW_FEEDBACK
        ? ['empty', 'feedback', 'plant-info-back']
        : ['empty', 'plant-info-back'];

      dispatch(enableWidgets(widgets));
      dispatch(setTheme('light'));
    } else {
      const widgets = process.env.REACT_APP_SHOW_FEEDBACK
        ? ['plant-info', 'feedback', 'plant-back']
        : ['plant-info', 'plant-back'];

      dispatch(enableWidgets(widgets));
      dispatch(setTheme('dark'));
    }
  }, [dispatch, activeInfo]);

  /*
   * HOTSPOT
   */
  // sync hovering between Hotspot and ButtBarItem
  const activateHoverButt = (hoveredButt) => {
    dispatch(hoverButt(hoveredButt));
  };

  // remove hover state between Hotspot and ButtBarItem
  const deactivateHoverButt = () => {
    dispatch(dehoverButt());
  };

  //  handles routing for clicked Hotspot and clearing values
  const clickedButt = useCallback(
    (activeButt) => {
      dispatch(activateTransition(`${plant.id}-${activeButt}`));
      dispatch(deactivateButt());
      dispatch(dehoverButt());
      dispatch(disableButtBar());
    },
    [dispatch, plant.id]
  );

  /*
   * BUTTBAR
   */
  useEffect(() => {
    if (!plant.id) return;
    //loop through units associated with plant
    plant.units.forEach((unit) => {
      // create ButtBar with unit information
      dispatch(createButt(unit.id, unit.title));
    });

    // make ButtBar active
    dispatch(enableButtBar());
    dispatch(activateButtBar());

    // prevents the butt bar from flooding as the user clicks around
    return () => {
      dispatch(clearButts());
    };
  }, [dispatch, plant, units]);

  // uses callback function established for Hotspot click to keep things synced
  useEffect(() => {
    if (activeButt.length) clickedButt(activeButt);
  }, [dispatch, activeButt, clickedButt]);

  return (
    <PlantView
      activeInfo={activeInfo}
      closePlantWideInfo={closeInfo}
      hoveredButt={hoveredButt}
      plant={plant}
      plantWideAssets={plantWideAssets}
      units={units}
      onClick={clickedButt}
      closePlantModal={closePlantModal}
      showPlantModal={showPlantModal}
      showModal={showModal}
      plantsEnabled={plantsEnabled}
      onMouseEnter={activateHoverButt}
      onMouseLeave={deactivateHoverButt}
    />
  );
};

export default withRouter(PlantContainer);
