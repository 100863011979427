// @flow

import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import './ButtBar.scss';
import ButtBarItem from 'features/ButtBar/ButtBarItem/ButtBarItem';

import type { Butt } from 'types';

type Props = {
  active: boolean,
  butts: Butt[],
  enabled: boolean,
  activeButt: string,
  hoverButt: string,
  onClick?: (string) => void,
  onMouseEnter?: (string) => void,
  onMouseLeave?: (string) => void
};

/**
 * Generates visual representation of ButtBarItem collection
 * Positioned along bottom of screen, and includes z-index,
 * active/inactive transition, and horizontal scrollbar for "responsiveness"
 *
 * @author Abigail Kesler <https://github.com/abbingail>
 */

const ButtBar = (props: Props) => {
  const {
    active,
    butts,
    enabled,
    activeButt,
    hoverButt,
    onClick,
    onMouseEnter,
    onMouseLeave
  } = props;

  return (
    <div id="bottom-bar" className={`${active ? 'is-active' : ''}`}>
      <Scrollbars hideTracksWhenNotNeeded={true}>
        <div className="bb-group">
          {butts.map((cheek) => {
            return (
              <ButtBarItem
                key={cheek.id}
                active={cheek.id === activeButt}
                enabled={enabled}
                hover={cheek.id === hoverButt}
                id={cheek.id}
                title={cheek.title}
                onClick={onClick}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
              />
            );
          })}
        </div>
      </Scrollbars>
    </div>
  );
};

export default ButtBar;
