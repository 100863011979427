// @flow

import { createSelector } from 'reselect';

/**
 * Returns whether or not the library is currently fetching from the service
 * @returns boolean
 */
export const getIsFetching = createSelector(
  (state) => state.library,
  (library) => library.isFetching
);

/**
 * Return the library asset ids
 * @returns string[]
 */
export const getLibrary = createSelector(
  (state) => state.library,
  (library) => library.assets
);
