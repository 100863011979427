// @flow

import React from 'react';
import { useDispatch } from 'react-redux';

import './UnitView.scss';
import FullScreenBackground from 'components/FullScreenBackground/FullScreenBackground';
import Panel from 'components/Panel/Panel';
import TabbedPanel from 'features/TabbedPanel';
import Title from 'components/Title/Title';

import { deactivateTransition } from 'store/transition/actions';

import type { Unit } from 'types';
import type { Tab } from 'features/TabbedPanel/types';

type Props = {
  activePanel: string,
  deactivateTabbedPanel: () => void,
  unit: Unit,
  tabPanels: Array<{
    id: string,
    tabs: Tab[]
  }>
};

const UnitView = (props: Props) => {
  const { activePanel, deactivateTabbedPanel, tabPanels, unit } = props;
  const dispatch = useDispatch();

  const handleOverviewLoaded = () => {
    setTimeout(() => {
      dispatch(deactivateTransition());
    }, 500);
  };

  return (
    <div id="unit-container">
      <Panel type="butt">
        <FullScreenBackground
          image={unit.overview}
          onLoaded={handleOverviewLoaded}
        />
        <Title copy={unit.title} theme="dark" />

        {tabPanels.map((tabs) => {
          return (
            <div
              key={`tab-panel-${tabs.id}`}
              className={`tab-panel-container ${
                tabs.id === activePanel ? 'is-active' : ''
              } ${activePanel === '' ? 'no-delay' : ''}`}
            >
              <TabbedPanel
                tabs={tabs.tabs}
                title={unit.title}
                closeTabContainer={deactivateTabbedPanel}
              />
            </div>
          );
        })}
      </Panel>
    </div>
  );
};

export default UnitView;
