// @flow

import React from 'react';

import './Tab.scss';

type Props = {
  id: string,
  activeTab: boolean,
  title: string,
  tabOnClick: (string) => void
};

/*
 * Creates clickable tabs that will display a FilteredList,
 * SimpleCardList, or Content based on user selection
 *
 * @author Abigail Kesler <https://github.com/abbingail>
 */

const Tab = (props: Props) => {
  const { id, activeTab, title, tabOnClick } = props;

  function handleOnClick() {
    tabOnClick(id);
  }

  return (
    <div
      className={`tab ${activeTab ? 'is-active' : ''}`}
      onClick={handleOnClick}
    >
      <h5>{title}</h5>
    </div>
  );
};

export default Tab;
