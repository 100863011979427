// @flow

import { createSelector } from 'reselect';

/**
 * Returns true if _any_ transition video is currently loading.
 * Returns false if all current transition videos are loaded.
 * @return boolean
 */
export const getIsFetching = createSelector(
  (state) => state.transition,
  (transition) => {
    const transits = transition.transits;
    const keys = Object.keys(transits);

    for (let i = 0; i < keys.length; i++) {
      if (transits[keys[i]].isFetching) return true;
    }

    return false;
  }
);

/**
 * Returns the full list of transit objects, with the
 * Transition id as keys
 * @return an object containing all the Transitions
 */
export const getTransitions = createSelector(
  (state) => state.transition,
  (transition) => transition.transits
);

/**
 * Returns the transit object with the given ID.
 *
 * @param string id Transit to fetch
 * @return Transition
 */
export const getTransition = (id: string) => {
  return createSelector(
    (state) => state.transition,
    (transition) => transition.transits[id]
  );
};
