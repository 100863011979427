// @flow

import React from 'react';

type Props = {
  iconTitle: string
};

const PlusIcon = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      aria-labelledby={props.iconTitle}
    >
      <title id={props.iconTitle}>Plus Icon</title>

      <polygon points="32,15 17,15 17,0 15,0 15,15 0,15 0,17 15,17 15,32 17,32 17,17 32,17 " />
    </svg>
  );
};

export default PlusIcon;
