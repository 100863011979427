// @flow

import { createSelector } from 'reselect';

/**
 * Returns the id of the currently active Butt
 */
export const getActiveButt = createSelector(
  (state) => state.butt,
  (butt) => butt.activeButt
);

/**
 * Returns the id of the currently hovered Butt
 */
export const getHoverButt = createSelector(
  (state) => state.butt,
  (butt) => butt.hoverButt
);

/**
 * Returns the active state of the butt bar
 */
export const getButtBarActive = createSelector(
  (state) => state.butt,
  (butt) => butt.active
);

/**
 * Returns the enabled state of the butt bar
 */
export const getButtBarEnabled = createSelector(
  (state) => state.butt,
  (butt) => butt.enabled
);

/**
 * Returns the entire array of current butts
 */
export const getButts = createSelector(
  (state) => state.butt.butts,
  (butts) => butts
);
