// @flow

import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import './Article.scss';

type Props = {
  body: string
};

const Article = (props: Props) => {
  const { body } = props;

  return (
    <div className="article-container">
      <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true}>
        <div className="body" dangerouslySetInnerHTML={{ __html: body }} />
      </Scrollbars>
    </div>
  );
};

export default Article;
