// @flow

// ============================
// ACTION TYPES
// ============================
export const ACTIONS = {
  CURRENT: {
    SET: 'CURRENT::SET'
  }
};

// ============================
// ACTION CREATORS
// ============================
export const setCurrent = (
  key: string,
  value: string | number | boolean | string[] | number[] | boolean[]
) => {
  return {
    type: ACTIONS.CURRENT.SET,
    key,
    value
  };
};
