// @flow

import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import './PlantCard.scss';
import ImageWithPlaceholder from 'components/ImageWithPlaceholder/ImageWithPlaceholder';
import PlusIcon from 'icons/PlusIcon';

type Props = {
  image: string,
  title: string,
  path: string
};

/**
 * Creates a simple image based card that will take an image path to
 * display, a title to be displayed, and a path to link to.
 *
 * Has an overlay with a Plus Icon centered when the image is hovered
 *
 * @author Matthew Wilson <github.com/matthewwewilson>
 */

const PlantCard = (props: Props) => {
  const { image, path, title } = props;
  // tracks whether or not the overlay should be active
  const [active, setActive] = useState(false);

  const mouseEnterHandler = () => {
    setActive(true);
  };

  const mouseLeaveHandler = () => {
    setActive(false);
  };

  return (
    <div className={`plant-card ${active ? 'is-active' : ''}`}>
      <Link to={path}>
        <div
          className="image"
          onMouseEnter={mouseEnterHandler}
          onMouseLeave={mouseLeaveHandler}
        >
          <ImageWithPlaceholder src={image} alt={title} />
          <div className="overlay" />
          <div className="plus-icon">
            <PlusIcon iconTitle="PlusIcon" />
          </div>
        </div>
      </Link>
      <div className="plant-card-title w-bold">{title}</div>
    </div>
  );
};

export default PlantCard;
