// @flow

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, RouterHistory } from 'react-router-dom';

import SideBar from './SideBar/SideBar';
import { ThemeProvider } from './ThemeContext';
import { setCurrent } from 'store/current/actions';
import { getActiveWidgets } from 'store/sidebar/selectors';
import { getPlantStructure } from 'store/plant/selectors';

type Props = {
  history: RouterHistory
};

/**
 * The main "business logic" behind the SideBar feature.
 *
 * Manages local state:
 * - drawerActive - whether or not the Drawer is open
 * - activeDrawerItem - ID of currently active DrawerItem (this is also
 *   used to determine the active Tier 1 Menu)
 * - activeMenuItem - ID of the currently active Tier 1 MenuItem (this is also
 *   used to determine the active Tier 2 Menu)
 *
 * Utilizes the store to output Widgets, fetch the menu structure, and
 * fetch the title
 *
 * DrawerItems are statically defined.
 *
 * @author Todd Miller <github.com/Toddses>
 */
const SideBarContainer = (props: Props) => {
  const dispatch = useDispatch();
  const [drawerActive, setDrawerActive] = useState(false);
  const [activeDrawerItem, setActiveDrawerItem] = useState('');
  const [activeMenuItem, setActiveMenuItem] = useState('');
  const plants = useSelector(getPlantStructure);
  const activeWidgets = useSelector(getActiveWidgets);

  // click handler for the specialized Hamburger Widget
  // Opens and closes the Drawer
  const handleHamburgerClick = () => {
    setDrawerActive(!drawerActive);
    setActiveDrawerItem('');
    setActiveMenuItem('');
  };

  // click handler for the Library DrawerItem
  const openLibrary = () => {
    setDrawerActive(false);
    setActiveDrawerItem('');
    setActiveMenuItem('');
    window.open(
      'https://www.flowserve.com/en/resources/knowledge-center/',
      '_blank'
    );
  };

  // click handler for Plant Selection DrawerItem
  const openPlantsMenu = () => {
    setActiveDrawerItem('plants');
    setActiveMenuItem('');
  };

  // hover handler for Tier One MenuItems
  const tierOneMouseEnter = (id: string) => {
    setActiveMenuItem(id);
  };

  // click handler for Tier One MenuItems
  const tierOneClick = (id: string, path: string = '') => {
    setDrawerActive(false);
    setActiveDrawerItem('');
    setActiveMenuItem('');
    dispatch(setCurrent('LoadingActive', true));
    if (path) props.history.push(path);
    else props.history.push(`/plants/${id}`);
  };

  // click handler for the Tier Two MenuItems
  const tierTwoClick = (id: string) => {
    setDrawerActive(false);
    setActiveDrawerItem('');
    setActiveMenuItem('');
    dispatch(setCurrent('LoadingActive', true));
    props.history.push(`/plants/${activeMenuItem}/${id}`);
  };

  // DrawerItem definitions
  const drawerItems = [
    {
      id: 'plants',
      copy: 'Plant Selection',
      handler: openPlantsMenu,
      tierOne: plants,
      tierOneViewAll: '/plants',
      tierTwo: {}
    },
    {
      id: 'library',
      copy: 'Library',
      handler: openLibrary,
      tierOne: [],
      tierTwo: {}
    }
  ];

  return (
    <ThemeProvider>
      <SideBar
        widgets={activeWidgets}
        hamburgerClick={handleHamburgerClick}
        drawerActive={drawerActive}
        drawerItems={drawerItems}
        activeDrawerItem={activeDrawerItem}
        activeMenuItem={activeMenuItem}
        tierOneMouseEnter={tierOneMouseEnter}
        tierOneClick={tierOneClick}
        tierTwoClick={tierTwoClick}
      />
    </ThemeProvider>
  );
};

export default withRouter(SideBarContainer);
