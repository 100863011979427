// @flow

import { createSelector } from 'reselect';

/**
 * Returns the assets with the id(s) passed in
 *
 * @param ids string|string[]
 * @return Asset|Asset[]
 */
export const getAssets = (ids: string | string[]) => {
  return createSelector(
    (state) => state.asset,
    (asset) => {
      if (Array.isArray(ids)) {
        const assets = [];

        ids.forEach((id) => {
          if (asset.hasOwnProperty(id)) assets.push(asset[id]);
          else {
            if (process.env.REACT_APP_DEBUG_LOGGING)
              console.debug(`Asset "${id}" not found in store`);
          }
        });

        return assets;
      } else {
        if (asset.hasOwnProperty(ids)) return asset[ids];

        if (process.env.REACT_APP_DEBUG_LOGGING)
          console.debug(`Asset "${ids}" not found in store`);
        return {};
      }
    }
  );
};
