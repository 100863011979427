// @flow

import React from 'react';

import './Widget.scss';
import { useThemeContext } from '../ThemeContext';

import type { Element } from 'react';

type Props = {
  copy: string,
  icon: Element<any> | null,
  id: string,
  handler: (string | void) => void
};

/**
 * Outputs a Widget element.
 * - copy will appear in hover state
 * - icon should be a ready-to-render SVG icon Component
 * - id is a unique identifier that is used to identify which widget was clicked
 * - handler is the function to execute when the Widget is clicked
 *
 * Utilize the ThemeContext to apply styles based on the current theme
 *
 * @author Todd Miller <github.com/Toddses>
 */
const Widget = (props: Props) => {
  const { copy, icon, id, handler } = props;
  const theme = useThemeContext();

  const clickHandler = () => {
    handler(id);
  };

  return id === 'empty' ? (
    <div className={`side-bar-widget empty`} />
  ) : (
    <div className={`side-bar-widget ${theme.theme}`} onClick={clickHandler}>
      <div className="widget-wrapper">
        <div className="background" />

        <div className="widget-icon">{icon}</div>
        <div className="widget-copy">
          <p className="inner w-bold">{copy}</p>
        </div>
      </div>
    </div>
  );
};

export default Widget;
