// @flow

import { ACTIONS } from './actions';

import type { Product } from 'types';

export type ProductState = {
  [key: string]: {
    isFetching: boolean,
    hasError: boolean,
    error: {
      message: string,
      status: number
    },
    product: Product
  }
};

export type ProductAction = {
  type: string | null,
  product: Product,
  id: string,
  error: {
    message: string,
    status: number
  }
};

const defaultState = {};

// product reducer
const product = (state: ProductState = defaultState, action: ProductAction) => {
  switch (action.type) {
    case ACTIONS.PRODUCT.REQUEST:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          isFetching: true
        }
      };

    case ACTIONS.PRODUCT.ERROR:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          isFetching: false,
          hasError: true,
          errorData: action.error
        }
      };

    case ACTIONS.PRODUCT.RECEIVE:
      return {
        ...state,
        [action.product.id]: {
          ...state[action.product.id],
          isFetching: false,
          hasError: false,
          product: action.product
        }
      };

    default:
      return state;
  }
};

export default product;
