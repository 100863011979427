// @flow

import React, { useState } from 'react';

import './LandingCTA.scss';

import type { Element } from 'react';

type Props = {
  id: string,
  body: string,
  buttonText: string,
  icon: Element<any>,
  title: string,
  url: string | null,
  ctaClick: (string, boolean | void) => void
};

const LandingCTA = (props: Props) => {
  const { id, body, buttonText, icon, title, url, ctaClick } = props;

  /* STATE */
  const [hover, setHovered] = useState(false);

  /* STATE FUNCTIONS for CTA hover */
  function handleOnMouseEnter() {
    setHovered(true);
  }

  function handleOnMouseLeave() {
    setHovered(false);
  }

  const handleCTAClick = () => {
    if (!ctaClick) return;
    if (url) ctaClick(url, true);
    else ctaClick(id);
  };

  return (
    <div className={`landing-cta ${hover ? 'is-hovered' : ''}`}>
      {icon}
      <div className="cta-title">{title}</div>
      <h4 className="cta-body w-book">{body}</h4>
      <div
        onClick={handleCTAClick}
        className="cta-link"
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      >
        {buttonText}
      </div>
    </div>
  );
};

export default LandingCTA;
