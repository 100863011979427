// @flow

import React from 'react';

type Props = {
  iconTitle: string
};

const MarketingLitIcon = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 26.9"
      aria-labelledby={props.iconTitle}
    >
      <title id={props.iconTitle}>Marketing Literature Icon</title>

      <path d="M32,2.9h-3.7V0h-4.4c-4.7,0-6.5,1.5-8,2.9H0v22.3l12.7,0v1.7h5.9v-1.7l13.3,0V2.9z M30.5,4.4v17.8l-11.7,0 c1.3-0.9,2.9-1.4,4.5-1.3l5,0V4.4H30.5z M23.9,1.5h2.9v17.9l-3.5,0c-2.6-0.1-5.1,1-6.8,2.8l0-17.8l0.4-0.3 C18.4,2.8,19.8,1.5,23.9,1.5z M15,4.4l0,17.8l-13.5,0V4.4H15z" />
    </svg>
  );
};

export default MarketingLitIcon;
