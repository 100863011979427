// @flow

import { createSelector } from 'reselect';

/**
 * Return the boolean value for the given products hasError property.
 *
 * @param id string The product ID to check against
 * @return boolean | null
 */
export const getHasError = (id: string) => {
  return createSelector(
    (state) => state.product,
    (product) => {
      if (product[id]) return product[id].hasError;
      else {
        if (process.env.REACT_APP_DEBUG_LOGGING)
          console.debug(`No product with the id "${id}" exists in the store`);
        return null;
      }
    }
  );
};

/**
 * Returns the data associated with the given product id(s).
 * Note you can pass either a single string, and return a
 * single product,
 * Or you can pass an array of string ids, and get back an
 * array of products.
 *
 * @param ids string|string[] ids The product ID or IDs to fetch
 * @return Product|Product[]
 */
export const getProducts = (ids: string | string[]) => {
  return createSelector(
    (state) => state.product,
    (product) => {
      if (Array.isArray(ids)) {
        const products = [];

        ids.forEach((id) => {
          if (product[id]) products.push(product[id].product);
          else {
            if (process.env.REACT_APP_DEBUG_LOGGING)
              console.debug(
                `No product with the id "${id}" exists in the store`
              );
          }
        });

        return products;
      } else {
        if (product[ids] && product[ids].product) return product[ids].product;

        if (process.env.REACT_APP_DEBUG_LOGGING)
          console.debug(`No product with the id "${ids}" exists in the store`);
        return {};
      }
    }
  );
};

/**
 * Fetches the string title associated with the given ID.
 * @param  string id The product ID to fetch
 * @return string
 */
export const getProductTitle = (id: string) => {
  return createSelector(
    (state) => state.product,
    (product) => {
      if (product[id]) return product[id].product.title;

      if (process.env.REACT_APP_DEBUG_LOGGING)
        console.debug(`No product with the id "${id}" exists in the store`);
      return '';
    }
  );
};
