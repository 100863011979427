// @flow

import React from 'react';

import './TabbedPanel.scss';
import TabBar from '../TabBar/TabBar';
import Title from 'components/Title/Title';
import CloseIcon from 'icons/CloseIcon';

import type { Tab } from 'features/TabbedPanel/types';

type Props = {
  activeTab: string,
  tabs: Tab[],
  title: string,
  closeTabContainer?: () => void,
  tabOnClick: (string) => void
};

/*
 * Outputs the unit title, TabBar and the related information,
 * and an option to close the overlay
 *
 * @author Abigail Kesler <https://github.com/abbingail>
 */

const TabbedPanel = (props: Props) => {
  const { activeTab, tabs, title, closeTabContainer, tabOnClick } = props;

  return (
    <div className="tab-slide">
      <div className="tab-top">
        <Title copy={title} theme={'light'} />
        <div className="close-icon" onClick={closeTabContainer}>
          <CloseIcon iconTitle="close-icon" />
        </div>
        <TabBar tabs={tabs} activeTab={activeTab} tabOnClick={tabOnClick} />
      </div>

      <div className="tab-content-container">
        {tabs.map((tab) => {
          return (
            <div
              key={tab.id}
              className={`tab-content ${
                activeTab === tab.id ? 'is-active' : ''
              }`}
            >
              {tab.panel}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TabbedPanel;
