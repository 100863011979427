//@flow

import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import './LiteratureSelector.scss';
import LiteraturePager from 'components/ProductView/LiteraturePager/LiteraturePager';
import LiteratureType from 'components/ProductView/LiteratureType/LiteratureType';

type Props = {
  activeLitType: string,
  title: string,
  description: string,
  litType: any,
  pagerInfo: any[],
  pagerOnClick: (string) => void,
  selectorOnClick: (string) => void
};

/**
 * Outputs the product title, description. available assets
 * and a pager for the previous and next products.
 *
 * Items outside the pager are wrapped in a scrollbar for responsive
 * behavior.
 *
 * @author Abigail Kesler <https://github.com/abbingail>
 */

const LiteratureSelector = (props: Props) => {
  const {
    activeLitType,
    title,
    description,
    litType,
    pagerInfo,
    pagerOnClick,
    selectorOnClick
  } = props;

  return (
    <div className="lit-sidebar">
      <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true}>
        <div className="sidebar-top">
          <div className="top-group">
            <h1>{title}</h1>
            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: description }}
            />
            <div className="lit-types">
              {litType.map((type) => {
                return (
                  <LiteratureType
                    key={type.id}
                    activeLitType={activeLitType === type.id}
                    id={type.id}
                    icon={type.icon}
                    title={type.title}
                    disabled={type.disabled}
                    selectorOnClick={selectorOnClick}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </Scrollbars>

      {pagerInfo.length ? (
        <div className="sidebar-bottom">
          {pagerInfo.map((item, index) => {
            return (
              <LiteraturePager
                key={`${item.id}-${index}`}
                id={item.id}
                type={item.type}
                pagerOnClick={pagerOnClick}
              />
            );
          })}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default LiteratureSelector;
