// @flow

import * as React from 'react';

type Props = {
  iconTitle: string
};

const DecokingToolIcon = (props: Props) => {
  const { iconTitle } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>Decoking Tool Icon</title>

      <path
        d="M61.629,47,59.941,34H52.559L50.871,47H49.129L47.441,34H40.059L38.371,47H34V66H66V47ZM54.316,36h3.868l.584,4.5H53.732Zm-.843,6.5h5.554l.584,4.5H52.889ZM50.611,49h.139V64h-1.5V49h1.361ZM41.816,36h3.868l.584,4.5H41.232Zm-.843,6.5h5.554l.584,4.5H40.389ZM36,49h2.25V64H36ZM64,64H61.75V49H64Z"
        transform="translate(-34 -34)"
      />
    </svg>
  );
};

export default DecokingToolIcon;
