// @flow

import React from 'react';

import './ProductCard.scss';
import SimpleCard from '../SimpleCard';
import StarIcon from 'icons/StarIcon';

type Props = {
  certifications?: string[],
  description?: string,
  image: string,
  path: string,
  primary?: boolean,
  title: string
};

/**
 * Uses SimpleCard composition to output a card that adds optional
 * copy - a short description and a list of certifications
 * @author Todd Miller <github.com/Toddses>
 */
const ProductCard = (props: Props) => {
  const {
    description = '',
    certifications = [],
    image,
    path,
    primary = false,
    title
  } = props;
  const certString = certifications.join('; ');

  return (
    <SimpleCard image={image} overlayCopy="View" title={title} path={path}>
      <ul className="product-card-copy">
        {description ? <li>{description}</li> : ''}
        {certString ? <li>{certString}</li> : ''}
      </ul>

      {primary ? (
        <div className="product-card-primary-wrapper">
          <StarIcon iconTitle="primary-star-icon" />
          <small>Primary</small>
        </div>
      ) : (
        ''
      )}
    </SimpleCard>
  );
};

export default ProductCard;
