// @flow

import { createSelector } from 'reselect';

export const getIsFetching = (plantId: string) => {
  return createSelector(
    (state) => state.unit,
    (unit) => {
      if (!unit[plantId]) return true;

      return unit[plantId].isFetching;
    }
  );
};

export const getUnit = (plantId: string, unitId: string) => {
  return createSelector(
    (state) => state.unit,
    (unit) => {
      if (!unit[plantId]) {
        if (process.env.REACT_APP_DEBUG_LOGGING)
          console.debug(`Cannot get unit for "${plantId}", id does not exist!`);
        return {};
      }

      const item = unit[plantId].units.find((el) => el.id === unitId);

      if (!item) {
        if (process.env.REACT_APP_DEBUG_LOGGING)
          console.debug(
            `Cannot get "${unitId}" in "${plantId}", id does not exist!`
          );
        return {};
      }

      return item;
    }
  );
};

/**
 * Returns an array of Units attached to the given
 * plantId.
 * Returns empty array if plantId doesn't exist
 *
 * @return Todd Miller <github.com/Toddses>
 */
export const getUnits = (plantId: string) => {
  return createSelector(
    (state) => state.unit,
    (unit) => {
      if (unit[plantId]) {
        return unit[plantId].units;
      } else {
        if (process.env.REACT_APP_DEBUG_LOGGING)
          console.debug(
            `Cannot get units for "${plantId}", id does not exist!`
          );
        return [];
      }
    }
  );
};
